import React, { createContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { debounce } from "lodash"; // Make sure to install lodash
import Home from "./components/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PropertyDetailpage from "./pages/PropertyDetailpage";
import Projectlistpage from "./pages/Projectlistpage";
import HomeLoanpage from "./pages/HomeLoanpage";
import Aboutuspage from "./pages/Aboutuspage";
import Contactuspage from "./pages/Contactuspage";
import Preloader from "./components/Preloader";
import InnerPageLoader from "./components/InnerPageLoader";
import BlogDetailpage from "./pages/BlogDetailpage";
import RelatedPostpage from "./pages/RelatedPostpage";
import Residentialprojectspage from "./pages/Residentialprojectspage";
import CommercialProjectspage from "./pages/CommercialProjectspage";
import Plotsprojectspage from "./pages/Plotsprojectspage";
import Hotprojectspage from "./pages/Hotprojectspage";
import BuilderDetailpage from "./pages/BuilderDetailpage";
import SearchResultsPage from "./pages/SearchResultsPage";
import CityDetailpage from "./pages/CityDetailpage";
import Mainlayout from "./components/Mainlayout";
import BuilderDetailComponent from "./components/BuilderDetailcomponent";

import {
  fetchData,
  // topUpcomingProjects,
  fetchCategories,
  allcitiesprojects,
  brandpartners,
  // highlightedProjects,
  getnewsandblogdata,
  getbloglistdata,
  getHomeBannerContent,
  getHomepageBanners,
  // fetchNewLaunchProjects,
} from "./apis/callbacks";

import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "./App.css";
import Loginpage from "./pages/Loginpage";
import AdminComponent from "./components/AdminComponent";
import TermsAndConditionComponent from "./components/TermsAndConditionComponent";
import Desclaimer from "./components/Desclaimer";
import Devloperlist from "./components/Devloperlist";
import BuilderlistingPage from "./pages/BuilderlistingPage";
import NewLaunchcityDynamicPage from "./pages/NewLaunchCitiesvisePage";
import NewlaunchCityPage from "./components/NewlaunchCityPage";
import NewLaunchDynamicPage from "./pages/NewLaunchCitiesvisePage";
import NewLaunchCitiesvisePage from "./pages/NewLaunchCitiesvisePage";
import Residentailpagecitiesvise from "./pages/Residentailpagecitiesvise";
import CommericialCitievisePage from "./pages/CommericialCitievisePage";
import PlotscitievisePage from "./pages/PlotscitievisePage";
import ScrollToTop from "./components/ScrollToTop";
import TopBuilderlistpage from "./pages/TopBuilderlistpage";
import WebsiteLoginComponent from "./components/WebsiteLoginComponent";

export const CityIdContext = createContext();
export const CityNameContext = createContext();

const App = () => {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [apiData, setApiData] = useState({});
  const [cityId, setCityId] = useState();
  const [city, setCity] = useState();
  const location = useLocation();

  useEffect(() => {
    AOS.init();

    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const debouncedLoadData = debounce(async (id) => {
    setUpdating(true);
    try {
      const [topProjects, upcomingProjects, highlighted] = await Promise.all([
        fetchData(id),
        // topUpcomingProjects(id),
        // highlightedProjects(id),
      ]);

      setApiData((prevData) => ({
        ...prevData,
        topProjects,
        upcomingProjects,
        highlighted,
      }));
    } catch (error) {
      console.error("Error updating data:", error);
    } finally {
      setUpdating(false);
    }
  }, 300);

  useEffect(() => {
    if (!cityId) return;
    debouncedLoadData(cityId);
  }, [cityId]);

  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      try {
        const [
          categories,
          cities,
          brandPartners,
          newsAndBlogs,
          blogList,
          homeBannerContent,
          homepageBanners,
          newLaunchProjects,
        ] = await Promise.all([
          fetchCategories(),
          allcitiesprojects(),
          brandpartners(),
          getnewsandblogdata(),
          getbloglistdata(),
          getHomeBannerContent(),
          getHomepageBanners(),
          // fetchNewLaunchProjects(),
        ]);

        setApiData({
          categories,
          cities,
          brandPartners,
          newsAndBlogs,
          blogList,
          homeBannerContent,
          homepageBanners,
          newLaunchProjects,
        });
      } catch (error) {
        console.error("Error loading initial data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadInitialData();
  }, []);

  const storeCityId = (id) => {
    setCityId(id);
  };

  const storeCityName = (city) => {
    try {
      setCity(city);
      console.log("stored city name", city);
    } catch (error) {
      console.log("storing city error", error);
    }
  };

  return (
    <CityIdContext.Provider value={{ cityId, storeCityId }}>
      <CityNameContext.Provider value={{ city, storeCityName }}>
        {/* <ScrollToTop /> */}
        <div className="app-container">
          <Header cityName={city} />
          <Routes>
            <Route
              path="/"
              element={<Home storeCityName={storeCityName} apiData={apiData} />}
            />
            <Route
              path="/property-detail/:propertyid/:slug"
              element={<PropertyDetailpage apiData={apiData} />}
            />
            <Route
              path="/new-launch"
              element={<Projectlistpage apiData={apiData} />}
            />
            <Route
              path="/home-loan"
              element={<HomeLoanpage apiData={apiData} />}
            />
            <Route
              path="/about-us"
              element={<Aboutuspage apiData={apiData} />}
            />
            <Route
              path="/contact-us"
              element={<Contactuspage apiData={apiData} />}
            />
            <Route
              path="/blog-detail/:id"
              element={<BlogDetailpage apiData={apiData} />}
            />
            <Route
              path="/blog-list"
              element={<RelatedPostpage apiData={apiData} />}
            />
            <Route
              path="/residential-projects"
              element={<Residentialprojectspage apiData={apiData} />}
            />
            <Route
              path="commercial-Projects"
              element={<CommercialProjectspage apiData={apiData} />}
            />
            <Route
              path="plots"
              element={<Plotsprojectspage apiData={apiData} />}
            />
            <Route
              path="/hot-projects"
              element={<Hotprojectspage apiData={apiData} />}
            />
            <Route
              path="/builder-details/:id"
              element={<BuilderDetailpage />}
            />
            <Route
              path="/search-result"
              element={<SearchResultsPage apiData={apiData} />}
            />
            <Route
              path="/city-detail/:cityName/:lid?"
              element={<CityDetailpage />}
            />
            <Route path="/login" element={<WebsiteLoginComponent />} />
            <Route path="/admin" element={<Mainlayout />} />
            <Route
              path="/terms-condition"
              element={<TermsAndConditionComponent />}
            />
            <Route path="/Desclaimer" element={<Desclaimer />} />
            {/* <Route path ="/property-by-top-builder" element ={<Devloperlist/>}/> */}
            <Route
              path="/properties-by-developer/:id"
              element={<BuilderlistingPage />}
            />
            <Route path="/top-builder" element={<TopBuilderlistpage />} />

            <Route
              path="/new-launchs/:cityIdN"
              element={<NewLaunchCitiesvisePage />}
            />

            <Route
              path="/residential-projects-list/:cityIdN"
              element={<Residentailpagecitiesvise />}
            />

            <Route
              path="/commercial-projects-list/:cityIdN"
              element={<CommericialCitievisePage />}
            />

            <Route
              path="/plots-projects-list/:cityIdN"
              element={<PlotscitievisePage />}
            />
          </Routes>
          <Footer />
          {(loading || updating) && (
            <div className="overlay">
              {loading ? (
                location.pathname === "/" ? (
                  <Preloader />
                ) : (
                  <InnerPageLoader />
                )
              ) : (
                <div className="updating-indicator">
                  <div className="inner-page-loader">
                    {/* Your loader content, e.g., a spinner */}

                    <div className="spinner"></div>
                  </div>
                </div> // You can replace this with a custom updating component
              )}
            </div>
          )}
        </div>
      </CityNameContext.Provider>
    </CityIdContext.Provider>
  );
};

export default App;
