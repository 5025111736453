import React from "react";
import BuilderDetailcomponent from "../components/BuilderDetailcomponent";
import Header from "../components/Header";

const BuilderDetailpage = () => {
  return (
    <div className="builder-section">
      <Header showBg={true} />
      <BuilderDetailcomponent />
    </div>
  );
};

export default BuilderDetailpage;
