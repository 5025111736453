import React from "react";
import Header from "../components/Header";
import HomeloanComponents from "../components/HomeloanComponents";

const HomeLoanpage = () => {
  return (
    <div>
      <Header showBg={true} />
      <HomeloanComponents />
    </div>
  );
};

export default HomeLoanpage;
